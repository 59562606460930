import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const BeforeAfter = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="container pd-top-100">
        <div className="row">
          <div className="col-lg-2 col-md-6"></div>
          <div className="col-lg-4 col-md-6 text-center">
            <h2>Before</h2>
            <img src={rpdata?.gallery?.[1]} alt="Before" />
          </div>
          <div className="col-lg-4 col-md-6 text-center">
            <h2>Before</h2>
            <img src={rpdata?.gallery?.[3]} alt="Before" />
          </div>
          <div className="col-lg-2 col-md-6"></div>
        </div>
    </div>
  );
};

export default BeforeAfter;

import React from "react";

const LiveChat = () => {
  return (
    <div>
      <div>
        <div class="elfsight-app-3bc46fc6-ac43-45e3-aa4f-3027fd060a54"></div>
      </div>
      <div className="visor_Counter">
        <div class="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
    </div>
  );
};

export default LiveChat;
